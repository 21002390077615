<template>
  <div>
    <Page :tabList="tabList" :getPageList="getPageList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList:[],
      getPageList: [
        { name: "季度统计", path: "/Quarter" },
        { name: "月度统计", path: "/Monthly" },
        { name: "抽题统计", path: "/DrawqStatist" },
        { name: "近期问题点统计", path: "/QuestionPoints" },
        // { name: "新增问题记录", path: "/AddQuestionRecord" },
        // { name: "监控记录", path: "/MonitorRecord" },
        { name: "审核员统计", path: "/AuditorStatistics" },
        { name: "过程部门设置", path: "/dept-process" },
      ],
    };
  },
};
</script>

<style>
</style>